@use './main.scss';

@import 'npm:flag-icons/css/flag-icons.min.css';

/**************************
        BANNER
***************************/
.banner {
  text-align: center;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  background: linear-gradient(
      rgba(182, 225, 238, 0.281),
      rgba(157, 191, 218, 0.658)
    ),
    url('../img/bg/jj-ying-7JX0-bfiuxQ-unsplash.jpg') no-repeat scroll center /
      cover;

  @include main.min-desktop-width() {
    background: linear-gradient(
        rgba(182, 225, 238, 0.281),
        rgba(157, 191, 218, 0.658)
      ),
      url('../img/bg/jj-ying-7JX0-bfiuxQ-unsplash.jpg') no-repeat fixed center /
        cover;
  }

  & &-content {
    position: absolute;
    bottom: 2em;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    @include main.min-tablet-width() {
      bottom: 1em;
    }

    h1,
    h5,
    h6,
    p {
      color: black;
    }
  }

  .cv {
    font-size: 1.3rem;
    font-family: 'Lato', bold;
    color: #3a2a2a;
    font-weight: 800;
    margin: 1em 0 0.8em 0;

    // https://codepen.io/dillansimmons/pen/qZPzQr
    &.social {
      a {
        text-align: center;
        line-height: 38px;
        border-radius: 50%;
        color: #414345;
        display: inline-block;
        height: 40px;
        margin-left: 10px;
        margin-right: 10px;
        width: 44px;

        &.uk-cool {
          border: 2px solid #e6e628;
        }

        &.it-cool {
          border: 2px solid #b92613;
        }

        &:hover {
          /* if hover broken this image link is probably broken 😞 */
          background: url(https://img.freepik.com/free-vector/space-with-stars-universe-space-infinity-background_78474-99.jpg?size=626&ext=jpg);
          transition: background 0.2s;
          animation: bgscroll 200s infinite linear;
          -webkit-animation: bgscroll 200s infinite linear;
          -ms-animation: bgscroll 200s infinite linear;
          -o-animation: bgscroll 200s infinite linear;
          border: 2px solid #414345;
          color: #fff;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12);
        }

        @keyframes bgscroll {
          0% {
            background-position: 0 0;
          }
          25%,
          75% {
            background-position: -2506px 2000px;
          }
          50% {
            background-position: 2000px -2000;
          }
          100% {
            background-position: 1000 0;
          }
        }
      }
    }
  }

  .arrow {
    width: 45px;
    height: 45px;
  }
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.img-attrib {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 0.5em 1.5em 0;
    padding: 0.25em;
    font-size: 0.8em;
    color: white;
    background-color: rgba(0, 0, 0, 0.2);

    &.top {
      top: 0;
      bottom: unset;
    }
  }
}
/**************************
          SECTIONS
***************************/
.wrapper {
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;
  padding: 1.5em;
  position: relative;
}

.section-title {
  position: absolute;
  font-weight: 600;
  top: 25px;
  left: 50%;
  transform: translate(-50%, -50%);
}

img {
  max-width: 100%;
}

#portfolio {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../img/bg/koushik-chowdavarapu-aWBPk_GBaCk-unsplash.jpg') no-repeat
      fixed center / cover;
  position: relative;
}

#gallery {
  margin: 0 auto;
  padding: 0;

  div.card {
    height: 200px;
    overflow: auto;

    background: rgba(244, 244, 244, 0.898);
    border-radius: 4px;
    position: relative;
    text-align: center;

    @include main.min-desktop-width() {
      height: 280px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &.os {
      height: auto;
      padding-bottom: 0.5em;
    }

    p {
      font-size: 0.87em;
      color: #000000;
      font-weight: 600;
      padding: 0.2em;
      margin: 0;
    }
    a {
      text-decoration: none;
    }

    .card-img-top {
      height: 100px;
      object-fit: scale-down;
    }

    .card-body {
      align-content: center;
    }
  }
}

/**************************
ABOUT
***************************/
#about {
  &.cont {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9)),
      url('../img/bg/henry-co--odUkx8C2gg-unsplash.jpg') no-repeat fixed center /
        cover;
  }

  p,
  h4 {
    color: #ffffff;
    text-align: justify;
  }

  a {
    color: inherit;
  }

  .wid {
    width: 90%;
  }

  .profile-pic {
    display: block;
    max-width: 180px;
    margin: 0 auto;
    border-radius: 40%;

    @include main.min-desktop-width() {
      margin-top: 0.4em;
    }

    @include main.max-mobile-width() {
      padding-bottom: 0.4em;
    }
  }
}

/**************************
PERSONAL INFO & CAROUSEL
***************************/
#extra-info {
  background-color: rgba(214, 146, 47, 0.4);
  width: 100%;
  $carousel-control-icon-width: 1rem;
  $carousel-control-color: rgb(0, 0, 0);

  #info {
    width: 100%;
    @include main.min-tablet-width() {
      width: 45%;
      float: left;
    }

    ul {
      list-style-type: georgian;
      text-align: justify;
      padding-left: 25px;
    }

    li {
      padding-bottom: 5px;
    }
  }

  #contatti {
    display: flex;
    align-items: center;
    justify-content: center;

    @include main.min-tablet-width() {
      height: 100%;
      width: 50%;
      float: right;
    }

    .carousel-indicators {
      margin-bottom: 0.3rem;
    }

    .carousel-inner {
      height: 300px;
    }

    .carousel-inner img {
      object-fit: cover;
    }
    .carousel-inner img.main {
      object-fit: cover;
      height: 100%;
    }

    .carousel-caption {
      background: rgba(0, 0, 0, 0.6);
      font-size: 0.8rem;
      line-height: 0.8rem;
      font-weight: 700;
      bottom: 0;
      position: sticky;
    }

    .no-back {
      background: none;
      max-height: 100%;
      height: 85%;
    }

    #current-job {
      width: 210px;
      height: 40px;
      padding-bottom: 4px;

      @include main.min-desktop-width() {
        width: 320px;
        height: 60px;
        padding-bottom: 2px;
      }

      @include main.min-xl-width() {
        width: 350px;
        height: 70px;
        padding-bottom: 2px;
      }
    }

    .other {
      width: 90px;
      height: 35px;
    }
  }
}
