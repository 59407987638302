@use './normalize.css';
@import 'npm:bootswatch/dist/cerulean/variables';
@import 'npm:bootstrap/scss/bootstrap';
@import 'npm:bootswatch/dist/cerulean/bootswatch';

@mixin max-mobile-width() {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin max-tablet-width() {
  @media screen and (max-width: 959px) {
    @content;
  }
}

@mixin min-tablet-width() {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin min-desktop-width() {
  @media screen and (min-width: 960px) {
    @content;
  }
}

@mixin min-xl-width() {
  @media screen and (min-width: 1100px) {
    @content;
  }
}

/**************************
        GENERAL
***************************/
:root {
  font-family: 'Lato', sans-serif;
  --bs-body-font-family: 'Lato', sans-serif;
  font-size: 14px;
  --bs-body-font-size: 14px;
}

body {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.centerimg {
  box-sizing: border-box;
  padding: 10px;
}

.clearfix:after {
  content: ' ';
  display: table;
  clear: both;
}

.divider {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 30px;
  margin-top: 30px;
  position: relative;
  width: 50%;
  margin-right: auto;
  margin-left: auto;

  &:before {
    background: #fff;
    border: 1px solid #f0f0f0;
    content: '';
    height: 20px;
    left: 50%;
    margin-left: -15px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 20px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }

  &:after {
    background: #556270;
    border: 1px solid #556270;
    content: '';
    height: 10px;
    left: 50%;
    margin-left: -10px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 10px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }
}
/**************************
        HEADER
***************************/
header {
  + div,
  + article {
    padding-top: 5.5em;
  }

  a {
    text-decoration: none;
  }

  #logo {
    text-align: center;
    margin: 0.5em;
    justify-content: center;

    @include min-tablet-width() {
      margin: 0;
      margin-left: 3%;
      padding: 0.5em;
    }
  }

  h1 {
    color: white;
    font-family: 'Lato', bold;
    font-size: 1.5rem;
    margin: 0;

    a {
      text-decoration: none;
    }
  }

  h2 {
    color: #ffffff;
    font-family: 'Lato', bold;
    margin: 2px 0 4px 0;
    font-size: 1.1rem;
  }

  h3 {
    text-align: center;
  }

  nav {
    font-family: 'Lato', bold;
    background-color: rgb(132, 172, 211);
    opacity: 0.7;
    text-align: center;
    padding: 0.5em;

    a {
      color: #fff;
      font-weight: bold;
      padding: 0 2px;
      transition: all 0.2s;
      text-decoration: none;

      &:hover {
        color: #9c9c9c;
        text-decoration: none;
      }
    }

    @include min-tablet-width() {
      margin-right: 3%;
      background: none;
      text-align: right;
      opacity: 1;
      flex-grow: 1;

      a {
        padding: 0 5px;
        font-weight: bold;
        font-size: 0.95em;
        text-decoration: none;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1em;
      list-style: none;
      height: 100%;
      padding: 0;
      margin: 0;

      @include min-tablet-width() {
        justify-content: end;
      }
    }
    li {
      display: inline-block;
    }
  }

  &.main-header {
    background-color: rgba(2, 40, 115, 0.7);
    margin: 0 0 0 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;

    @include min-tablet-width() {
      align-content: space-between;
      display: flex;
      padding: 0.4em;
    }

    @include max-mobile-width() {
      h1 {
        font-size: 1.4em;
      }
    }
  }
}

/**************************
        FOOTER
***************************/
.main-footer {
  margin-top: auto;
  padding: 0.8em;
  background-color: rgba(102, 180, 239, 0.5);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 0.4em;

  p {
    font-size: 0.85em;
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .icona_social {
    width: 24px;
    height: 24px;
    margin: 0 0.3em;
  }
}
